import { colors } from 'utils/colors'
import { Text } from 'components/Typography'
import { Button } from 'components/button/Button'

export const PhoneInputError = () => {
  return (
    <div
      css={{
        padding: '1rem',
        backgroundColor: colors.red[100],
        borderRadius: '0.25rem',
      }}
    >
      <Text variant="bodySmall">
        Something went wrong with the phone input. Please{' '}
        <Button
          type="button"
          variant="text"
          size="small"
          fitContentVertically
          css={{ display: 'inline' }}
          onClick={() => window.location.reload()}
        >
          refresh the page
        </Button>{' '}
        and try again.
      </Text>
    </div>
  )
}
