import { PromoDiscountType } from 'api/goodtrust/promo'
import { isAfter, isBefore } from 'date-fns'
import { describePromoOffer } from 'logic/promo/describePromoOffer'
import { bound } from 'utils/class'
import { matchMap } from 'utils/general'
import { getRemainingUsages } from 'utils/promo'
import { ApiType } from 'utils/types'

export function describePromotion(
  promo: ApiType['PromoCodeInfoResponse'] | ApiType['ClaimedPromoCodeResponse']
) {
  return bound({
    isExpired(expirationType: 'for-claiming' | 'for-redeeming') {
      return !!this.determineUsageRangePosition(expirationType)?.isAfter
    },
    isBeforeValidRange() {
      return !!this.determineUsageRangePosition('for-claiming')?.isBefore
    },
    determineUsageRangePosition(expirationType: 'for-claiming' | 'for-redeeming') {
      const deadline = new Date(
        matchMap(expirationType, {
          'for-claiming': promo.to,
          'for-redeeming': promo.use_before,
        }) ?? ''
      ).getTime()
      const rangeStart = new Date(promo.from ?? '').getTime()
      if (Number.isNaN(deadline) || Number.isNaN(rangeStart)) return undefined

      const rangePosition = {
        isAfter: isAfter(Date.now(), deadline),
        isBefore: isBefore(Date.now(), rangeStart),
      }

      const isOutside = rangePosition.isBefore || rangePosition.isAfter
      const isInside = !isOutside

      return {
        ...rangePosition,
        isOutside,
        isInside,
      }
    },
    canBeConsideredInfinite() {
      return !!promo.to && isAfter(new Date(promo.to), new Date('2070-01-01'))
    },
    canRedeemSubscriptionOffer(offer: ApiType['OfferValidationResponse']) {
      return !this.isExpired('for-redeeming') && !!promo.active && offer.status === 'VALID'
    },
    canRedeemDiscountOffer(
      offer: ApiType['ClaimedOfferResponse'],
      discountType?: PromoDiscountType
    ) {
      return (
        describePromoOffer(offer).doesOfferLimitConformToCheckout(discountType) &&
        getRemainingUsages(offer) > 0 &&
        !this.isExpired('for-redeeming')
      )
    },
  })
}
