import { Maybe } from '@graphql-tools/utils'
import { PromoDiscountType } from 'api/goodtrust/promo'
import {
  getSubscriptionMap,
  postCreateSubscription,
  postLifetimePrepare,
} from 'api/goodtrust/subscription'
import { getUserMe } from 'api/goodtrust/user'
import { AdditionalRedeemInfo, RedeemResult } from 'logic/promo/type'
import { describeSubscriptions } from 'logic/subscription/describe'
import { describeCyberscoutCountry } from 'logic/subscription/plan/cyberscout/describe'
import { describeMyPlans } from 'logic/subscription/plan/my/describe'
import { planSpecs } from 'logic/subscription/plan/spec'
import { ANALYTICS } from 'utils/analytics/consts'
import { events } from 'utils/analytics/events'
import { fireEvent } from 'utils/analytics/fire'
import { bound } from 'utils/class'
import { MessageException, ShouldNeverHappenError } from 'utils/error'
import { unwrapResponse } from 'utils/fetcher'
import { formatPrice } from 'utils/format'
import { ident, isOneOf, matchMap, noop } from 'utils/general'
import { offerSpecs } from 'utils/promo/spec'
import {
  AnimationPackOffer,
  DiscountOffer,
  FreeSubscriptionOffer,
  FreeSubscriptionOfferType,
  OfferResponse,
} from 'utils/promo/types'
import { ApiType } from 'utils/types'

export function isFreeSubscriptionOffer(offer?: OfferResponse): offer is FreeSubscriptionOffer {
  if (!offer?.type) return false

  return isOneOf(
    offer.type,
    ident<FreeSubscriptionOfferType[]>([
      'ANIMATION_SUBSCRIPTION_FREE',
      'ESTATE_FAMILY_PLAN',
      'DIGITAL_SAFETY_FREE',
      'ESTATE_PLAN_FREE',
      'ULTIMATE_PLAN_FREE',
      'STORY_PLUS_FREE',
    ])
  )
}

export function isAnimationPackOffer(
  offer?: ApiType['OfferResponse']
): offer is AnimationPackOffer {
  return offer?.type === 'ANIMATION_PACK_FREE'
}

export function isDiscountOffer(offer?: ApiType['OfferResponse']): offer is DiscountOffer {
  return offer?.type === 'DISCOUNT'
}

export function describePromoOffer(offer: ApiType['OfferResponse']) {
  return bound({
    asFreeSubscription() {
      return isFreeSubscriptionOffer(offer) ? offer : undefined
    },
    asDiscount() {
      return isDiscountOffer(offer) ? offer : undefined
    },
    asAnimationPack() {
      return isAnimationPackOffer(offer) ? offer : undefined
    },
    isLifetimeOffer() {
      return offer?.amount != null && this.asFreeSubscription() && offer.amount < 0
    },
    isEstateFamilyPlan() {
      return offer?.type === 'ESTATE_FAMILY_PLAN'
    },
    doesOfferRedeemingRequireAdditionalInformation() {
      const { type: offerType } = offer
      if (!offerType) return false
      const offerSpec = offerSpecs[offerType]
      if (!offerSpec.freeSubscription) return false

      const planSpec = planSpecs[offerSpec.freeSubscription]

      return !!planSpec.includesDashlaneAndCyberscout
    },
    async redeemFreeSubscriptionOffer(
      promo_code: string,
      info: AdditionalRedeemInfo | undefined,
      email?: string
    ) {
      if (!offer.type) throw new ShouldNeverHappenError()
      if (!this.asFreeSubscription()) {
        throw new MessageException('offer_isnt_free')
      }

      const offerSpec = offerSpecs[offer.type]
      const offerPlan = offerSpec.freeSubscription
      if (!offerPlan) throw new ShouldNeverHappenError()

      let userMe: Awaited<ReturnType<typeof getUserMe>> | undefined = undefined
      try {
        userMe = await getUserMe()
      } catch {}
      const offerPlanSpec = planSpecs[offerPlan]
      const activePlan = describeMyPlans(userMe?.json ?? {})
        .forPlanGroup(offerPlanSpec.planGroup)
        .getActivePlan()
      const loggedInEmail = userMe?.json?.email

      async function track() {
        if (!offerPlan) throw new ShouldNeverHappenError()

        matchMap(offerPlanSpec.planGroup, {
          MAIN: async () =>
            events.premium_activated_promo.fire({
              previousPlan: activePlan,
              activatedPlan: offerPlan,
            }),
          ANIMATION: () => {
            fireEvent('animation_subscription_purchase', {
              base_price: 0,
              paid_price: 0,
              pack_type: planSpecs.STORY_PLUS.animationsPerWeek ?? 0,
              type: 'paid',
              product: ANALYTICS.flow ?? 'unknown',
            })
          },
          GROUP_LIFE: () => {
            // IMPROVE [Group Life] should there be analytics here?
            // No there won't
          },
          STORY_PLUS: () => {
            // IMPROVE fire some analytics here?
            // Not right now.
          },
          ESTATE: noop,
        })
      }

      const redeemMethod = this.isLifetimeOffer() ? 'lifetime' : 'create_subscription'

      const commonBody: Pick<
        ApiType['SubscriptionLifetimeRequest'] & ApiType['SubscriptionCreateRequest'],
        'promo_code' | 'zip' | 'country_code' | 'first_name' | 'last_name'
      > = {
        promo_code,
        zip: info?.address_zip,
        country_code: info?.country,
        first_name: info?.first_name,
        last_name: info?.last_name,
      }

      const result = await matchMap(redeemMethod, {
        lifetime: async (): Promise<RedeemResult> => {
          if (
            isOneOf(offerPlan, [
              'FREE_PLAN_MAIN',
              'FREE_PLAN_ANIMATION',
              'FREE_PLAN_GROUP_LIFE',
              'FREE_PLAN_ESTATE',
            ])
          )
            throw new ShouldNeverHappenError()

          if (email) {
            await postLifetimePrepare({
              email,
              subscription_type: offerPlan,
              ...commonBody,
            }).then(unwrapResponse)
          } else if (loggedInEmail) {
            await postLifetimePrepare({
              email: loggedInEmail,
              subscription_type: offerPlan,
              ...commonBody,
            }).then(unwrapResponse)
          } else {
            throw new ShouldNeverHappenError()
          }

          return 'redeemed'
        },
        create_subscription: async (): Promise<RedeemResult> => {
          const map = await getSubscriptionMap().then(unwrapResponse.body)

          const { isEligibleForCyberscout } = describeCyberscoutCountry(info?.country)

          const subscription = describeSubscriptions(map).pickPlan(offerPlan)?.pickVariant({
            isEligibleForCyberscout: isEligibleForCyberscout(),
          })

          if (!subscription) throw Error('Could not get correct subscription.')
          const emailToUse = email || userMe?.json?.email
          if (emailToUse) {
            const res = await postCreateSubscription({
              email: emailToUse,
              price_id: subscription.price_id,
              ...commonBody,
            })
            if (!res.ok && res.errorCode === 'user_active_subscription')
              return 'already-active-subscription'
            unwrapResponse(res)
          } else {
            throw new ShouldNeverHappenError()
          }

          return 'redeemed'
        },
      })()

      await track()

      return result
    },
    doesOfferLimitConformToCheckout(discountType?: PromoDiscountType) {
      const types = offer?.discount_types
      if (!types || types.length === 0 || !discountType) return true

      return types.some((type) => discountType === type || type === 'ALL')
    },
    formatOfferDiscount() {
      if (offer?.type !== 'DISCOUNT' || offer.amount == null) return undefined
      if (offer.option === 'PERCENTAGE') return `${offer.amount * 100}%`
      if (offer.option === 'ABSOLUTE') return `$${formatPrice(offer.amount, 0)}`
      return undefined
    },
    getDiscountedPrice(fullCents: Maybe<number>) {
      if (fullCents != null && offer?.type === 'DISCOUNT' && offer?.amount) {
        if (offer.option === 'PERCENTAGE') {
          return fullCents * (1 - offer.amount)
        } else if (offer.option === 'ABSOLUTE') {
          return Math.max(0, fullCents - offer.amount * 100)
        }
      }

      return undefined
    },
  })
}
